import type { SubmitButtonTranslationObj } from "../../translation-keys/submit-button-text-keys.js";

//Currently some button texts are passed thru widgets or container.
//We can make these fallback text
const DownloadSubmitButtonText = "Get Your Download";

const SubmitButtonMapText: SubmitButtonTranslationObj = {
"document-Submit-Button": DownloadSubmitButtonText,
"document-landing-page-Submit-Button": DownloadSubmitButtonText,
"post-event-assets-Submit-Button": "Get Instant Access",
"ppc-Submit-Button": DownloadSubmitButtonText,
"ppc-contact-Submit-Button": "Get Some Answers",
"case-study-Submit-Button": "Download Free Case Study",
"video-Submit-Button": DownloadSubmitButtonText,
"video-landing-page-Submit-Button": "Get Instant Access",
"video-gated-Submit-Button": "Get Instant Access",
"contact-Submit-Button": "Get Answers",
"qx-roi-Submit-Button": "Schedule Your ROI Assessment Today",
"mx-roi-Submit-Button": "Schedule Your ROI Assessment Today",
"spark-Submit-Button": "Contact a Spark Expert",
"pricing-Submit-Button": "Get Pricing",
"subscription-Submit-Button": "Subscribe",
"partner-Submit-Button": "Submit",
"demo-request-Submit-Button": "Schedule Your Demo",
"tradeshow-giveaway-Submit-Button": "Submit",
"event-registration-Submit-Button": "Register Now",
"subprocessor-update-notification-Submit-Button": "Submit",
"digital-maturity-assessment-Submit-Button": "Access My Full Report",
"qx-demo-video-Submit-Button": "Watch the Demo!",
"ppc-video-Submit-Button": "Watch the Video"
};

export default SubmitButtonMapText;